<template>
  <div class="travelCateList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>旅游线路二级分类列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>分类名称:</span>
      <el-input v-model="list.cateName" placeholder="请输入分类名称"></el-input>
      <el-button type="primary" @click="getTravelCateList()">查找</el-button>
      <el-button type="primary" @click="addDialogVisible = true"
        >添加</el-button
      >
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column align="center" prop="dept" label="排序" width="width">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onChangeSort(row)"
            >{{ row.dept }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="id"
        label="分类ID"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="cateName"
        label="分类名称"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="cateImg"
        label="分类图片"
        width="width"
      >
        <template slot-scope="{ row }">
          <el-image
            style="width: 70px; height: 70px"
            :src="row.cateImg"
            :preview-src-list="[row.cateImg]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="fatherName"
        label="上级分类名称"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="addTime"
        label="最后修改时间"
        width="width"
      ></el-table-column>
      <el-table-column align="center" prop="statusStr" label="状态">
      </el-table-column>
      <el-table-column align="center" label="操作" width="300">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            v-if="row.status === 2"
            type="primary"
            @click="changeStatus(row.id, 1)"
            >上架</el-button
          >
          <el-button
            size="mini"
            v-if="row.status === 1"
            type="primary"
            @click="changeStatus(row.id, 2)"
            >下架</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="$router.push(`/thirdTravelCateList/${row.id}`)"
            >查看下级</el-button
          >
          <el-button type="primary" size="mini" @click="onEdit(row)"
            >编辑</el-button
          >
          <el-button type="primary" size="mini" @click="onDel(row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <!-- 添加分类弹层 -->
    <el-dialog
      :close-on-click-modal="false"
      title="添加分类"
      :visible.sync="addDialogVisible"
      width="30%"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="分类名称">
          <el-input
            v-model="addForm.cateName"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类图片">
          <send-image
            v-if="addDialogVisible"
            type="one"
            @addImageStr="(e) => addImageStr(e, 1)"
          ></send-image>
        </el-form-item>
        <el-form-item
          ><span style="color: red"
            >每张图片不能超过500K，建议尺寸：123*123px</span
          ></el-form-item
        >
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAddOk">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑分类弹层 -->
    <el-dialog
      :close-on-click-modal="false"
      title="编辑分类"
      :visible.sync="editDialogVisible"
      width="30%"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="分类名称">
          <el-input
            v-model="editForm.cateName"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类图片">
          <send-image
            v-if="editDialogVisible"
            :images="editForm.cateImg"
            type="one"
            @addImageStr="(e) => addImageStr(e, 2)"
          ></send-image>
        </el-form-item>
        <el-form-item
          ><span style="color: red"
            >每张图片不能超过500K，建议尺寸：123*123px</span
          ></el-form-item
        >
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onEditOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  travelCate,
  addTravelCate,
  changeSort,
  delTravelCate,
  travelCateDetails,
  editTravelCate,
  changeStatus,
} from "../../api/travel";
import { sendFile } from "../../api/sendFile";
import SendImage from "../../components/sendImage.vue";
export default {
  name: "travelCateList",
  components: { SendImage },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      addDialogVisible: false,
      editDialogVisible: false,
      addForm: {
        fatherId: Number(this.id),
        cateId: 0,
        cateName: "",
        cateImg: "",
      },
      list: {
        currentPage: 1,
        pageSize: 5,
        fatherId: Number(this.id),
        cateName: "",
      },
      editForm: {
        cateName: "",
        fatherId: Number(this.id),
        cateId: null,
        cateImg: "",
      },
      tableData: [],
      pagination: {},
    };
  },
  created() {
    this.getTravelCateList();
  },
  methods: {
    changeStatus(cateId, type) {
      this.$confirm("是否操作这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            cateId: cateId,
            status: type,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getTravelCateList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async getTravelCateList() {
      const { data } = await travelCate(this.list);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    async handleInputConfirm(row) {
      const { data } = await changeSort({
        cateId: row.id,
        dept: Number(row.dept),
      });
      if (data.code !== 0) {
        this.$message({
          message: "设置失败",
          type: "error",
        });
      } else {
        this.$message({
          message: "设置成功",
          type: "success",
        });
      }
      row.inputVisible = false;
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    async onAddOk() {
      if (this.addForm.cateName === "" || this.addForm.cateImg === "") {
        this.$message({
          message: "请把数据填写完整",
          type: "warning",
        });
        return;
      }
      const { data } = await addTravelCate(this.addForm);
      if (data.code == 0) {
        this.$message({
          message: "添加成功",
          type: "success",
        });
        this.addForm.cateName = "";
        this.addForm.cateImg = "";
      }
      this.addDialogVisible = false;
      this.getTravelCateList();
    },
    onClose() {
      this.addForm.cateImg = "";
    },
    onSendImg() {
      this.$refs.fileRefs.click();
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },
    //删除
    onDel(row) {
      this.$confirm("是否删除这条分类?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delTravelCate({
            cateId: row.id,
          });
          console.log(data);
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getTravelCateList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    addImageStr(image, type) {
      if (type === 1) {
        this.addForm.cateImg = image;
      } else if (type === 2) {
        this.editForm.cateImg = image;
      }
    },

    async onEdit(row) {
      const { data } = await travelCateDetails({
        cateId: row.id,
      });
      this.editForm.cateId = row.id;
      this.editForm.cateName = data.data[0].cateName;
      this.editForm.cateImg = data.data[0].cateImg;
      this.editDialogVisible = true;
    },
    async onEditOk() {
      if (this.editForm.cateName === "" || this.editForm.cateImg === "") {
        this.$message({
          type: "warning",
          message: "请把数据填写完整!",
        });
        return;
      }
      const { data } = await editTravelCate(this.editForm);
      console.log(data);
      if (data.code == 0) {
        this.editDialogVisible = false;
        this.getTravelCateList();
        this.$message({
          type: "success",
          message: "编辑成功",
        });
      } else {
        this.$message({
          type: "error",
          message: "编辑失败",
        });
      }
    },

    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getTravelCateList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getTravelCateList();
    },
  },
};
</script>
<style lang="less" scoped>
.travelCateList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 200px;
      margin: 0 40px 0 20px;
    }
  }
  .el-table {
    margin-top: 30px;
    .el-table__body-wrapper {
      table {
        td {
          .cell {
            img {
              width: 80px;
              height: 80px;
            }
          }
        }
      }
    }
  }
  /deep/.el-dialog {
    .el-form-item__content {
      display: flex;
      .img {
        position: relative;
        height: 100px;
        width: 100px;
        margin: 20px 20px;
        img {
          height: 100px;
          width: 100px;
          border: 1px solid #c0ccda;
        }
        div {
          position: absolute;
          top: 0;
          right: 2px;
          height: 10px;
          width: 10px;
          z-index: 100;
        }
      }
      .addImg {
        display: inline-block;
        width: 100px;
        height: 100px;
        border: 1px dashed #c0ccda;
        text-align: center;
        line-height: 100px;
        cursor: pointer;
        margin-top: 20px;
        i {
          width: 50px;
          height: 50px;
          font-size: 20px;
          color: #c0ccda;
        }
      }
    }
  }
  .el-pagination {
    margin-top: 30px;
    float: right;
  }
}
</style>